/**
 * @description 工具模块 不依赖第三方代码
 * @AUTH along
 * @DATA 2020-10-14
 */

/**
 * @description 日期格式化
 * @param date Date 目标日期对象/String 日期对应字符串/ Number: 时间戳(精确到毫秒)
 *        String fmt 指定格式字符串月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)可以用 1-2 个占位符、年(y)可以用 1-4 个占位符、毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @return Boolean
 */
export function dateFormat(dateTmp, fmtTmp) {
    let fmt = fmtTmp;

    let date = dateTmp;

    if (!fmt) {
        fmt = "yyyy.MM.dd";
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };

    let week = {
        "0": "日",
        "1": "一",
        "2": "二",
        "3": "三",
        "4": "四",
        "5": "五",
        "6": "六",
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (RegExp.$1.length > 1
                ? RegExp.$1.length > 2
                    ? "星期"
                    : "周"
                : "") + week[date.getDay() + ""]
        );
    }
    for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
}

/**
 * @description 生成随机字符串
 */
export function generateRandomStr() {
    return Math.random()
        .toString(36)
        .substr(2);
}

/**
 * @description 把总秒数转换为时分秒
 */
export function getHms (seconds, dateFormat) {
    let date = dateFormat;

    if (date == undefined) {
        date = 'H:i:s'; // 默认格式
    }
    let obj = {};

    obj.H = Number.parseInt(seconds / 3600);
    obj.i = Number.parseInt((seconds - obj.H * 3600) / 60);
    obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60);
    if (obj.H < 10) {
        obj.H = '0' + obj.H;
    }
    if (obj.i < 10) {
        obj.i = '0' + obj.i;
    }
    if (obj.s < 10) {
        obj.s = '0' + obj.s;
    }

    let rs = dateFormat
        .replace('H', obj.H)
        .replace('i', obj.i)
        .replace('s', obj.s);

    return rs;
}


/**
 * @description 日期显示
 * @param {*} timestamp //时间戳
 */
export function friendlyDate(timestamp, max='') {
    let formats = {
        'year': '%n% 年前',
        'month': '%n% 月前',
        'day': '%n% 天前',
        'hour': '%n% 小时前',
        'minute': '%n% 分钟前',
        'second': '%n%秒前',
    };

    let now = Date.now();

    let seconds = Math.floor((now - timestamp) / 1000);

    let minutes = Math.floor(seconds / 60);

    if(max == 'minutes' && minutes > 60){
        return dateFormat(timestamp, 'yyyy-MM-dd HH:mm');
    }
    let hours = Math.floor(minutes / 60);

    if(max == 'hour' && hours > 24){
        // return dateFormat(timestamp, 'yyyy-MM-dd HH:mm');
        return dateFormat(timestamp, 'MM-dd HH:mm');
    }
    let days = Math.floor(hours / 24);

    let months = Math.floor(days / 30);

    let years = Math.floor(months / 12);

    let diffType = '';

    let diffValue = 0;

    if (years > 0) {
        diffType = 'year';
        diffValue = years;
    } else if (months > 0) {
        diffType = 'month';
        diffValue = months;
    } else if (days > 0) {
        diffType = 'day';
        diffValue = days;
    } else if (hours > 0) {
        diffType = 'hour';
        diffValue = hours;
    } else if (minutes > 0) {
        diffType = 'minute';
        diffValue = minutes;
    } else {
        diffType = 'second';
        diffValue = seconds === 0 ? (seconds = 1) : seconds;
    }
    return formats[diffType].replace('%n%', diffValue);
}


/**
 * 指定范围时间戳转换小时/分钟/秒
 * @param   {val}           Number      //时间戳
 * @param   {Booleanc}      type        //false:小时/分钟/秒  true:天/小时/分钟/秒
 */
export function fnMinuteFormate(second){
    const hours = Math.floor((second % 86400) / 3600);
    const minutes = Math.floor(((second % 86400) % 3600) / 60);
    const seconds = Math.floor(((second % 86400) % 3600) % 60);
    const forMatDate = {
        hours: hours < 10 ? ('0' + hours) : hours,
        minutes: minutes < 10 ? ('0' + minutes) : minutes,
        seconds: seconds < 10 ? ('0' + seconds) : seconds
    };

    return forMatDate.hours + ':' + forMatDate.minutes + ':' + forMatDate.seconds;
}