/**
 * @description 设置session_ticket
 */
export default {
    namespaced: true,
    state: {
        session_ticket: ''
    },
    mutations: {
        SET_TICKET (state, ticket) {
            state.session_ticket = ticket;
        }
    }
};
