import { dateFormat, fnMinuteFormate, friendlyDate } from "./utils";

function dateFormatter(date, formatter) {
    if (date) {
        return dateFormat(date, formatter);
    } else {
        return "--";
    }
}

function dateFilter(second) {
    if (second) {
        return fnMinuteFormate(second);
    } else {
        return "--";
    }
}

// function dateFriendly(timestamp, max) {
//     if(timestamp) {
//         return friendlyDate(timestamp, max);
//     } else {
//         return '--';
//     }
// }

export { dateFormatter, dateFilter };
