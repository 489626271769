import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 异常处理
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

VueRouter.onError = error => {
    console.log(error, 'error');

    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = VueRouter.history.pending.fullPath;

    if (isChunkLoadFailed) {
        VueRouter.replace(targetPath);
    }
};

// 入口配置
const routes = [
    {
        path: "/",
        component: (resolve) => require(["@/views/index"], resolve),
        children: [{
            path: '',
            name: 'home',
            component: (resolve) => require(["@/views/feadback/index"], resolve),
        },{
            path: 'feadback/success',
            name: 'feadbackSuccess',
            component: (resolve) => require(["@/views/feadback/success"], resolve),
        }]
    },
    {
        path: "**",
        redirect: '/'
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
