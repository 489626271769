import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "@/assets/js/axios";
import "@/assets/css/reset.css";
import '@/assets/font/iconfont.css';
import "element-ui/lib/theme-chalk/index.css";
import * as filters from '@/assets/js/filters';

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(axios);

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

